import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { pxToRem } from '../../../../helpers/setRem';
import styles from './index.module.scss';

interface NormalFloatingComponentProps {
  imagePath: string; // Path to the PNG image
  title: string;
  description: string;
}

const NormalFloatingComponent: React.FC<NormalFloatingComponentProps> = ({ imagePath, title, description }) => {
  return (
    <Box sx={{
      backgroundColor: '#fff',
      padding: '12px 24px',
      borderRadius: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
      gap: pxToRem(24),
      width: 'max-content',
    }}>
      <Avatar
        src={imagePath}
        alt="Icon"
        sx={{ width: pxToRem(64), height: pxToRem(64) }} // Assuming a fixed size for the icon
      />
      <Box>
        <Typography className={styles.title} style={{color: '#1B1D24'}}>
          {title}
        </Typography>
        <Typography className={styles.longText} style={{color: '#555555'}}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default NormalFloatingComponent;