import { Fade, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "swiper/css/pagination";
import AboutUsTitle from "../../../assets/images/LandingPage/AboutUs/aboutUsTitlePic.png";
import AboutUsMobileTitle from "../../../assets/images/LandingPage/AboutUs/aboutUsMobileTitle.png";
import ValueImg from "../../../assets/images/LandingPage/AboutUs/valueImg.png";
import mobileValueImg from "../../../assets/images/LandingPage/AboutUs/mobileValueImg.png";
import keppleFullLogo from "../../../assets/images/public/KeppleLargeLogo.png"


import slide1 from "../../../assets/images/LandingPage/SlidesImg/slide1.png";
import slide2 from "../../../assets/images/LandingPage/SlidesImg/slide2.png";
import slide3 from "../../../assets/images/LandingPage/SlidesImg/slide3.png";
import slide4 from "../../../assets/images/LandingPage/SlidesImg/slide4.png";

import AnimationController from "../../../components/AnimationController/AnimationController";
import { pxToRem } from "../../../helpers/setRem";
import styles from "./index.module.scss";



const About = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:780px');
    const [activeSlide, setActiveSlide] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 100); // Delay might be adjusted based on your needs

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [pathname]);
    const slideTexts = [
        {
            title: "People",
            description: "Whether it's the people who work within our business or who interact with our products, their experience is our number one priority."
        },
        {
            title: "Committed  to Positive Impact",
            description: "Everything we do from the technology that we develop to the business models we establish, they must have positive impact."
        },
        {
            title: "Continuous Learning",
            description: "We're a for-profit company, focused on developing and operating technology to ensure we can continuously evolve and improve our solutions. Financial viability and a solid business model are essential considerations for us. Before moving forward with any project, we rigorously assess its potential to generate profit and sustain long-term growth, ensuring that we can continue delivering value while fulfilling our commitment to innovation."
        },
        {
            title: "Open to Collaborate",
            description: "Whether it's a new idea, an experience or a skillset, we are always looking to collaborate with great people.",
        }

    ];

    const CARSOUEL_CONFIG = [
        {
            imageUrl: slide1,
            description: slideTexts[0].description,  // Industry & Problem Validation
            title: slideTexts[0].title,
        },
        {
            imageUrl: slide2,
            description: slideTexts[1].description,  // The Ideal vs. Reality
            title: slideTexts[1].title,
        },
        {
            imageUrl: slide3,
            description: slideTexts[2].description,  // Financial Viability & Business Model
            title: slideTexts[2].title,
        },
        {
            imageUrl: slide4,
            description: slideTexts[3].description,  // Sprint to Launch
            title: slideTexts[3].title,
        },
    ];
    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0,  maxWidth: '1900px', width: '100%', alignSelf: 'center'}}>
            <Stack direction="column" marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <img src={ isMobile ? AboutUsMobileTitle :AboutUsTitle} alt="About Us" />
                            </Stack>

                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Typography className={styles.home_ApproachTitle}>
                                    Here to design and build modern technology for those who need it most.
                                </Typography>
                                <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_titleBold}>
                                        OUR STORY
                                    </Typography>
                                    <Stack className={styles.home_2_textBox}>
                                        <Typography className={styles.home_2_text}>
                                            Founded in late 2021,  the initial idea for Kepple was to build a mobile application that would provide the modern ride-share experience to schools sourcing casual relief teachers.<br /><br />
                                            The business plan was simple: pay teachers fairly, streamline the process for schools, and keep costs competitive by utilising technology to minimise overheads.<br /><br />
                                            Without any external investment, Kepple was able to sustainably scale the Spark Relief Teachers platform into a profitable, industry-leading solution that now allows the business to invest in new projects.<br /><br />
                                            The problem statement that schools needed a solution to securely store and utilise their alumni data was raised in late 2022. Within six months, Kepple had committed to designing and developing Loop Alumni. Within twelve months, the Beta was live. <br /><br />
                                            Loop is now an active service helping schools worldwide securely store and manage their alumni data.
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <Stack className={styles.home_2_imgContainer}>

                                </Stack>
                                <Stack className={styles.home_2_founderContainer}>
                                    <Stack className={styles.home_2_founderTitleBox}>
                                        <Typography className={styles.home_2_founderTitle}>
                                            MEET OUR FOUNDERS
                                        </Typography>
                                    </Stack>
                                    <Stack className={styles.home_2_founderBox}>
                                        <Typography className={styles.home_2_founderText}>
                                            Meet Andrew and James: childhood friends; an engineer and a teacher who were living together during the COVID-19 pandemic and decided they wanted to quit their respective jobs and start something new. <br /><br />
                                            With first-hand experience in the education space, James leads the Spark Relief Teachers product team and oversees all the day-to-day operations.  <br /><br />
                                            Andrew, who previously worked in construction project management, has a keen interest in technology and oversees the development team that designs, builds, and maintains Kepple's technical solutions.<br /><br />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>


                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />


                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_value}>
                                <Typography className={styles.home_value_founderTitle}>
                                    OUR VALUES
                                </Typography>

                                {!isMobile ? <img src={ValueImg} alt="Our Values" /> : <> <img src={mobileValueImg} alt="Our Values" /></>} 


                            </Stack>


                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_3}>
                                <Stack className={styles.home_3_Container}>
                                    <Stack className={styles.home_3_textBox}>
                                        <Typography className={styles.home_3_text}>
                                            OUR MISSION
                                        </Typography>
                                        <Typography className={styles.home_3_titleBold}>
                                            Creating meaningful impact with our products, while enjoying every step of the journey.
                                        </Typography>
                                    </Stack>

                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_4} direction={'column'}>
                                <Stack className={styles.home_4_Container}>
                                    <Stack className={styles.home_4_textBox} direction={'column'}>
                                        <Typography className={styles.home_3_titleBold}>
                                            We're always looking for great people to join our team.
                                        </Typography>
                                    </Stack>
                                    <button className={styles.contactButton} onClick={() => navigate('/career')}>
                                        <Typography className={styles.contactButtonText}>
                                            Careers at Kepple
                                        </Typography>
                                    </button>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
            </Stack>
        </div>
    );
};

export default About;
