import { DialogContent, Fade, Grid, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { getContactUsError, getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForInput, validateAgenda } from "../../../helpers/inputValidators";
import useTextInput from "../../../hooks/useTextInput";
import styles from "./Contact.module.scss";
import { useLocation } from "react-router-dom";
import { pxToRem } from "../../../helpers/setRem";
import { WhiteMultilineTextField, WhiteTextField } from "../../../components/TextField";
import UserAlert from "../../../components/AlertComponent/BasicAlert";
import { LoadingButton } from "@mui/lab";


const PitchPage = () => {
    const isMobile = useMediaQuery('(max-width:440px');
    const name = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "First Name", true) } });
    const title = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "Job Title", true) } });
    const whyKepple = useTextInput({ inputValidator: (input) => { return validateAgenda(input, 'This Field', true) } });
    const number = useTextInput({ inputValidator: (input) => { return getErrorMsgForContactNumber(input, true); } });
    const email = useTextInput({ inputValidator: (input) => { return getErrorMsgForEmailInput(input, true) } });
    const message = useTextInput({ inputValidator: (input) => { return getContactUsError(input, 2, "About You", true); } });
    const project = useTextInput({ inputValidator: (input) => { return getContactUsError(input, 2, "Project", true); } });


    const emailServerUrl = "https://public.herotofu.com/v1/ab7c67e0-92e0-11ed-a003-6f0b76086b1c";
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const textFieldProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const multilineProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                padding: 0,
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
            maxLength: 500
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const resetForm = () => {
        // Resetting the value to an empty string and marking it as untouched
        name.setValue("");
        name.setHasTouched(false);
    
        email.setValue("");
        email.setHasTouched(false);
    
        number.setValue("");
        number.setHasTouched(false);
    
        message.setValue("");
        message.setHasTouched(false);
    
        title.setValue("");
        title.setHasTouched(false);
    
        project.setValue("");
        project.setHasTouched(false);
    
        whyKepple.setValue("");
        whyKepple.setHasTouched(false);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        //NEED TO UPDATE
        const formattedMessage = 
                `CONTACT NUMBER: ${number.value}\n` +
                `JOB TITLE: ${title.value}\n` +
                `GOAL: ${message.value}\n` +
                `PROJECT: ${project.value}\n` +
                `WHY KEPPLE: ${whyKepple.value}\n`
        
        fetch(emailServerUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.value,
                name: name.value,
                message:formattedMessage,
         }),
        }).then((response) => {
            if (response.status == 200) {
                setOpenAlert(true);
                resetForm();
            } else {
                throw new Error();
            }
        }).catch((err) => {
            console.log(err.toString());
        }).finally(() => {
            setLoading(false);
        });
    }

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, [pathname])

    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0, width: '100%', alignSelf: 'center' }}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <UserAlert
                    open={openAlert}
                    handleClose={() => setOpenAlert(false)}
                    title="Thank you for reaching out!" // No title in your example
                    content="We have received your query and will endeavour to respond to you within 3 business days. We appreciate your patience as our team processes your request."
                    closeButtonText="Close"
                    closeOutlined={true}
                />
                <Stack className={styles.contact1}>
                    <h2>Work with Kepple</h2>
                    <p>Looking for someone to hear your idea and take it seriously? <br />Request to pitch your idea to Kepple and explore the possibility of bringing it to life together.</p>
                </Stack>

                <Stack className={styles.contact2}>
                    <h2>Tell us about your project</h2>
                    <p>All fields required</p>
                    <Fade in={true} timeout={1000}>
                        <Stack className={styles.formContainer}>

                            <form
                                action={emailServerUrl}
                                onSubmit={handleSubmit}>
                                <Typography className={styles.formTitle}>About You</Typography>
                                <Grid container gap={3} className={styles.gridContainer}>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            variant="filled"
                                            value={name.value}
                                            onChange={(e) => name.setValue(e.target.value)}
                                            label='Full Name'
                                            name="fullName"
                                            error={name.hasError && name.hasTouched}
                                            helperText={<small>{name.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={email.value}
                                            variant="filled"
                                            onChange={(e) => email.setValue(e.target.value)}
                                            label='Email Address'
                                            name="email"
                                            type="email"
                                            error={email.hasError && email.hasTouched}
                                            helperText={<small>{email.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={number.value}
                                            variant="filled"
                                            onChange={(e) => number.setValue(e.target.value)}
                                            label='Contact Number'
                                            name="number"
                                            error={number.hasError && number.hasTouched}
                                            helperText={<small>{number.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={title.value}
                                            variant="filled"
                                            onChange={(e) => title.setValue(e.target.value)}
                                            label='Job Title'
                                            name="number"
                                            error={title.hasError && title.hasTouched}
                                            helperText={<small>{title.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Stack width={'100%'} marginTop={pxToRem(24)}>
                                        <Typography className={styles.formTitle}>Your Project</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteMultilineTextField
                                            fullWidth
                                            value={project.value}
                                            onChange={(e) => project.setValue(e.target.value)}
                                            placeholder="Tell us about your project so we can give you the best help possible. "
                                            name="project"
                                            error={project.hasError && project.hasTouched}
                                            helperText={<small>{project.getErrorMessage()}</small>}
                                            multiline
                                            rows={2}
                                            required
                                            {...multilineProps}
                                        />
                                    </Grid>

                                    <Stack width={'100%'} marginTop={pxToRem(24)}>
                                        <Typography className={styles.formTitle}>How can we help you?</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteMultilineTextField
                                            fullWidth
                                            value={message.value}
                                            onChange={(e) => message.setValue(e.target.value)}
                                            placeholder="Share with us the goals of your project – the sky is the limit!"
                                            name="message"
                                            multiline
                                            error={message.hasError && message.hasTouched}
                                            helperText={<small>{message.getErrorMessage()}</small>}
                                            rows={2}
                                            required
                                            {...multilineProps}
                                        />
                                    </Grid>

                                    <Stack width={'100%'} marginTop={pxToRem(24)}>
                                        <Typography className={styles.formTitle}>Why Kepple?</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={whyKepple.value}
                                            variant="filled"
                                            onChange={(e) => whyKepple.setValue(e.target.value)}
                                            placeholder="We would love to know!"
                                            name="number"
                                            error={whyKepple.hasError && whyKepple.hasTouched}
                                            helperText={<small>{whyKepple.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Stack alignItems={'center'} marginTop={pxToRem(12)}>
                                            <LoadingButton
                                                disabled={
                                                    // Disable if any field has an error
                                                    name.hasError ||
                                                    email.hasError ||
                                                    number.hasError ||
                                                    message.hasError ||
                                                    project.hasError ||
                                                    title.hasError ||
                                                    whyKepple.hasError ||
                                                    // Or if any field has not been touched
                                                    name.value === '' ||
                                                    email.value === '' ||
                                                    number.value === '' ||
                                                    message.value === '' ||
                                                    project.value === '' ||
                                                    title.value === '' ||
                                                    whyKepple.value === ''
                                                }
                                                className={styles.sendButton}
                                                type="submit"
                                            >
                                                 {loading ? '' : 'SUBMIT REQUEST'}
                                                </LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Fade>
                </Stack>
            </Stack>
        </div>
    )
}


export default PitchPage;
