import React, { Suspense } from "react";
import styles from "./App.module.scss";
import ErrorBoundaries from "./components/ErrorBoundaries";
import Box from "@mui/material/Box";
import { Routes } from "react-router-dom";
import { ROOT_ROUTERS } from "./routers";
import LoadingSpinner from "./components/LoadingSpinner";
import Header from "./components/Header";
import Footer from "./components/Footer";
import DocumentMeta from "react-document-meta";
import { Provider } from "react-redux";
import store from "./spark-admin/redux/store";
import "./global/fonts.scss";
import { StyledEngineProvider } from "@mui/material/styles";

const meta = {
  title: "Kepple",
  description:
    "Melbourne-based tech start-up transforming today's cutting edge ideas into tomorrow's reality.",
};

function App() {
  return (
    <div>
      <ErrorBoundaries>
      <StyledEngineProvider injectFirst>
        <DocumentMeta {...meta} />
        <Provider store={store}>
          <Suspense fallback={<LoadingSpinner />}>
            <Box className={styles.pageFadeInAnimate}>
              {/* <Header /> */}
              <Routes>
                {Object.values(ROOT_ROUTERS).map((rootRouter) => rootRouter)}
              </Routes>
              {/* <Footer /> */}
            </Box>
          </Suspense>
        </Provider>
        </StyledEngineProvider>
      </ErrorBoundaries>
    </div>
  );
}

export default App;
