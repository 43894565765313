import { Fade, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoopImg from "../../../assets/images/LandingPage/Product/LoopImg.png";
import PaintImg from "../../../assets/images/LandingPage/Product/paintImg.png";

import "swiper/css/pagination";
import AppStore from "../../../assets/images/LandingPage/Product/appstore.png";
import GooglePlayStore from "../../../assets/images/LandingPage/Product/googleplay.png";

import BookingIcon from "../../../assets/images/LandingPage/Product/BookingIcon.png";
import FeedbackIcon from "../../../assets/images/LandingPage/Product/FeedbackIcon.png";
import PositionIcon from "../../../assets/images/LandingPage/Product/PositionIcon.png";
import AnimationController from "../../../components/AnimationController/AnimationController";
import { pxToRem } from "../../../helpers/setRem";
import FloatingComponent from "../component/FloatingComponent";
import styles from "./index.module.scss";

import { Button } from "react-bootstrap";


const Product = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:780px');
    const [activeSlide, setActiveSlide] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 100); // Delay might be adjusted based on your needs

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [pathname]);

    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0, maxWidth: '1900px', width: '100%', alignSelf: 'center' }}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_1}>
                                <Typography className={styles.home_title}>
                                    Our Products
                                </Typography>
                                <Typography className={styles.home_text}>
                                    Our technology is designed to make an impact in critical industries, with each of our products operating as its own business.
                                </Typography>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Stack className={styles.home_2_boxContainer}>
                                    <img className={styles.home_2_Img} src={PaintImg} alt="Our Product" />
                                </Stack>
                                <Stack className={styles.home_2_textContainer}>
                                    <Typography className={styles.home_2_title}>
                                        100% In-House
                                    </Typography>
                                    <Typography className={styles.home_2_text}>
                                        At Kepple, everything needed to build our technology and run the business is handled in-house. From UI/UX design and development to marketing, business planning, go-to-market strategy, and day-to-day operations, we do it all ourselves.<br /><br />
                                        This approach brings together a diverse team of creative thinkers, each with unique perspectives. This diversity drives innovation and helps us continuously improve and refine our products for lasting success.
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_3}>
                                <Stack className={styles.home_3_imgContainer}>
                                    {!isMobile && (
                                        <>
                                            <FloatingComponent
                                                imagePath={PositionIcon}
                                                title="Teachers Available Today"
                                                description="There are nearby teachers available to work at your school today"
                                                top="28"
                                                left="20"
                                            />
                                            <FloatingComponent
                                                imagePath={BookingIcon}
                                                title="Booking Confirmed"
                                                description="Spark High School has confirmed you for a booking tomorrow"
                                                top="45"
                                                left="20"
                                            />
                                            <FloatingComponent
                                                imagePath={FeedbackIcon}
                                                title="Feedback Received"
                                                description="Sarah has left you feedback about your placement today at Spark High School"
                                                top="69"
                                                left="30"
                                            />
                                        </>
                                    )}
                                </Stack>
                                <Stack className={styles.home_3_textContainer}>
                                    <Typography className={styles.home_3_title}>
                                        Spark Relief Teachers
                                    </Typography>
                                    <Typography className={styles.home_3_text}>
                                        Spark Relief Teachers is a mobile app that connects schools with casual relief teachers, similar to how modern ride-share apps link passengers with drivers.<br /><br />
                                        Launched in early 2022, the app has transformed the industry by improving pay rates for relief teachers and elevating the overall experience for schools sourcing temporary staff through an agency.
                                    </Typography>
                                    <button className={styles.ideaButton} onClick={() => window.open('https://www.sparkreliefteachers.com', '_blank', 'noopener,noreferrer')}>
                                        <Typography className={styles.ideaButtonText}>
                                            Learn more about Spark
                                        </Typography>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.1514 13.6543C20.1426 13.3467 20.0283 13.083 19.791 12.8457L12.9531 6.15723C12.751 5.96387 12.5137 5.8584 12.2236 5.8584C11.6348 5.8584 11.1777 6.31543 11.1777 6.9043C11.1777 7.18555 11.292 7.44922 11.4941 7.65137L17.6465 13.6543L11.4941 19.6572C11.292 19.8594 11.1777 20.1143 11.1777 20.4043C11.1777 20.9932 11.6348 21.4502 12.2236 21.4502C12.5049 21.4502 12.751 21.3447 12.9531 21.1514L19.791 14.4541C20.0371 14.2256 20.1514 13.9619 20.1514 13.6543Z" fill="white" />
                                        </svg>
                                    </button>
                                    <Stack direction={'row'} gap={pxToRem(24)} zIndex={1000}>
                                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                                            <Button className={styles.appButton}>
                                                <img src={AppStore} alt="Download on the App Store" />
                                            </Button>
                                        </a>
                                        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                                            <Button className={styles.appButton}>
                                                <img src={GooglePlayStore} alt="Get it on Google Play" />
                                            </Button>
                                        </a>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_4}>
                                <Stack className={styles.home_4_textContainer}>
                                    {isMobile &&
                                        <Stack className={styles.home_4_smallImgContainer}>
                                            <img className={styles.home_4_smallImg} src={LoopImg} alt="Loop Alumni management platform" />
                                        </Stack>}
                                    <Typography className={styles.home_3_title}>
                                        Loop  Alumni
                                    </Typography>
                                    <Typography className={styles.home_4_text}>
                                        Loop is an alumni management platform for schools worldwide, offering secure data storage and taking cues from modern CRM tools to help schools engage past students.<br /><br />
                                        Designed as an easy-to-use, plug-and-play solution for schools of any size, Loop helps institutions connect with their alumni, fostering engagement that benefits current students and the wider school community alike.
                                    </Typography>
                                    <button className={styles.loopButton} onClick={() => window.open('https://loopalumni.com', '_blank', 'noopener,noreferrer')}>
                                        <Typography className={styles.loopButtonText}>
                                            Learn more about Loop
                                        </Typography>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.1514 13.6543C20.1426 13.3467 20.0283 13.083 19.791 12.8457L12.9531 6.15723C12.751 5.96387 12.5137 5.8584 12.2236 5.8584C11.6348 5.8584 11.1777 6.31543 11.1777 6.9043C11.1777 7.18555 11.292 7.44922 11.4941 7.65137L17.6465 13.6543L11.4941 19.6572C11.292 19.8594 11.1777 20.1143 11.1777 20.4043C11.1777 20.9932 11.6348 21.4502 12.2236 21.4502C12.5049 21.4502 12.751 21.3447 12.9531 21.1514L19.791 14.4541C20.0371 14.2256 20.1514 13.9619 20.1514 13.6543Z" fill="white" />
                                        </svg>
                                    </button>
                                </Stack>
                                <Stack className={styles.home_4_imgContainer}>
                                    <img className={styles.home_4_img} src={LoopImg} alt="Loop Image" />
                                </Stack>

                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_5} direction={'column'}>
                                <Stack className={styles.home_5_Container}>
                                    <Stack className={styles.home_5_textBox} direction={'column'}>
                                        <Typography className={styles.home_5_titleBold}>
                                            Got an idea that could be the next big thing?
                                        </Typography>
                                        <Typography className={styles.home_5_text} color={'#fff'}>
                                            We're excited to explore new ideas and collaborate with motivated entrepreneurs. If you have an idea to share, click the button below to learn more about how we can work together.
                                        </Typography>
                                    </Stack>
                                    <button className={styles.contactButton} onClick={() => navigate('/pitch')}>
                                        <Typography className={styles.contactButtonText}>
                                            Pitch your Idea
                                        </Typography>
                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.1514 13.6543C20.1426 13.3467 20.0283 13.083 19.791 12.8457L12.9531 6.15723C12.751 5.96387 12.5137 5.8584 12.2236 5.8584C11.6348 5.8584 11.1777 6.31543 11.1777 6.9043C11.1777 7.18555 11.292 7.44922 11.4941 7.65137L17.6465 13.6543L11.4941 19.6572C11.292 19.8594 11.1777 20.1143 11.1777 20.4043C11.1777 20.9932 11.6348 21.4502 12.2236 21.4502C12.5049 21.4502 12.751 21.3447 12.9531 21.1514L19.791 14.4541C20.0371 14.2256 20.1514 13.9619 20.1514 13.6543Z" fill="white" />
                                        </svg>
                                    </button>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />


            </Stack>
        </div>
    );
};

export default Product;
