import { Stack } from "@mui/material";
import TransparentButton from "../../../../components/TransparentButton";
import { slideBasedOnMarginLeft } from "../../../../global/styles";
import { IContentWithAnimation } from "../../../../global/types";
import styles from "./SlideContentContainer.module.scss";
import { scroller } from "react-scroll";

interface ISlideContentContainer extends IContentWithAnimation {
  title: string;
  description: string;
}

const SlideContentContainer = ({
  loadedAnimation,
  title,
  description,
}: ISlideContentContainer) => (
  <Stack className={styles.textContainer}>
    <Stack className={styles.paragraphContainer}>
      <Stack
        className={`heading1 ${styles.title}`}
        style={{
          display: "inline-block",
          ...slideBasedOnMarginLeft(loadedAnimation, "0px", "120px"),
          transitionDelay: "0.5s",
        }}
      >
        {title}
      </Stack>
      <Stack
        className={`text ${styles.paragraph}`}
        style={{
          display: "inline-block",
          ...slideBasedOnMarginLeft(loadedAnimation, "0px", "120px"),
          transitionDelay: "0.7s",
        }}
      >
        {description}
      </Stack>

    </Stack>
  </Stack>
);

export default SlideContentContainer;
