export const transitionCubicBezier = {
  transition: "cubic-bezier(0.1, 0.7, 0.6, 0.9) 0.8s",
};

export const slideBasedOnMarginLeft = (
  loadedAnimation: boolean,
  startPoint: string,
  endPoint: string
) => ({
  opacity: loadedAnimation ? "100%" : "0%",
  marginLeft: loadedAnimation ? startPoint : endPoint,
  ...transitionCubicBezier,
});

export const fadeIn = (loadedAnimation: boolean) => ({
  opacity: loadedAnimation ? "100%" : "0%",
});

export const scaleUP = (loadedAnimation: boolean) => ({
  transform: loadedAnimation ? "scale(1)" : "scale(0.1)",
});

export const modalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
