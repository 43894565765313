import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "./carousel-wrapper.styles.scss";

interface ICarsouelWrapper {
  children: JSX.Element[];
  disableNavigation?: boolean;
  disablePagination?: boolean;
  delay?: number;
}

const CarouselWrapper = ({
  children,
  disableNavigation,
  disablePagination,
  delay,
}: ICarsouelWrapper) => {
  return (
    <Swiper
      navigation={disableNavigation ? false : true}
      loop={true}
      modules={
        disablePagination
          ? [Navigation, Autoplay]
          : [Navigation, Autoplay, Pagination]
      }
      autoplay={{
        delay: delay ?? 100000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      className='mySwiper'
    >
      {children.map((child, index) => (
        <SwiperSlide key={`SwiperSlide_${index}`}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CarouselWrapper;
