// Basic size, same as the number in webpack.config
export const baseSize = 16;
// Function to set rem
function setRem() {
  const clientScreenWidth = document.documentElement.clientWidth;
  if (clientScreenWidth >= 1280) {
    // Set to 1440 means the current design is based on 1440 width monitor
    const scale = clientScreenWidth / 1440;
    // Set font size of the root element
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  } else if (clientScreenWidth < 1280 && clientScreenWidth >= 780) {
    // Design width is for Ipad Air, whose widh is 780 px
    const scale = clientScreenWidth / 820;
    // Set font size of the root element
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  } else {
    // Design width is for iphone XR, whose widh is 414 px
    const scale = clientScreenWidth / 414;
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  }
}
// inilization
setRem();
// rem Reset rem when the size of window changes
window.onresize = function () {
  setRem();
};

export const pxToRem = (px: number) => {
  return `${px / 16}rem`;
};

