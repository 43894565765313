import { EMPTY_INPUT_HELPER_MSG } from "./shared";


export const getErrorMsgForEmailInput = (email: string, canBeEmpty?: boolean) => {
  if((email === '' || email === undefined) && canBeEmpty) {
    return;
  } else if (email.trim().length === 0 && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (!/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return "Invalid email";
  }
};

export const getErrorMsgForPasswordInput = (
  password: string,
  // In login page only check user have input password
  // this is because firebase reset password only have password strength of length is more than 6
  forLogin?: boolean
) => {
  if (password.length === 0) return EMPTY_INPUT_HELPER_MSG;
  if (forLogin) {
    if (password.trim().length < 5) {
      return "Please input password";
    }
  } else {
    if (
      password.length < 8 ||
      !/\d/.test(password) ||
      !/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(password)
    ) {
      return "Password must be at least 8 characters long, include at least one digit, and contain at least one special character";
    }
  }
};

export const getErrorMsgForInput = (input: string | undefined, minLength: number, name?: string, canBeEmpty?:boolean) => {
  if ((input === undefined || input.trim().length === 0) && canBeEmpty) {
    return;
  } else if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return name ? `${name} cannot be empty` : EMPTY_INPUT_HELPER_MSG;
  } else if (typeof input !== "string") {
    return `${name ? name : "Input"} should be a valid string`;
  } else if (input && !/^[a-zA-Z\s_'-]*$/.test(input)) {
    return `${name?name:'Input'} has invalid characters`;
  } else if (input && input.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getErrorMsgForCompanyName = (input: string | undefined, minLength: number, name?: string, canBeEmpty?:boolean) => {
  if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof input !== "string") {
    return `${name ? name : "Input"} should be a valid string`;
  } else if (input && !/^[a-zA-Z0-9\s.,!?_@#$%^&*:;<>{}[\](|)\\+=`"~'\/-]*$/.test(input)) {
    return "Invalid characters";
  } else if (input && input.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getErrorMsgForPostalCode = (postalCode: string | undefined, canBeEmpty?: boolean) => {
  const EMPTY_INPUT_HELPER_MSG = "Postal code is required";
  const postalCodePattern = /^[A-Za-z\d-]*$/; // Allow alphanumeric characters and hyphens
  if ((postalCode === undefined || postalCode.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (postalCode && !postalCodePattern.test(postalCode)) {
    return "Invalid characters";
  }
};

export const getErrorMsgForOrganizationName = (input: string | undefined,minLength: number, name?: string, canBeEmpty?: boolean): string | undefined => {
  const organizationNamePattern = /^[a-zA-Z0-9\s,.'-]*$/;
  if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (input && !organizationNamePattern.test(input)) {
    return "Invalid characters";
  }else if (input && input.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getErrorMsgForAddressComponents = (addressComponent: string | undefined, minLength: number, name?: string, canBeEmpty?: boolean): string | undefined => {
  const addressRegex = /^[a-zA-Z\s',.\-]*$/;
  if ((addressComponent === undefined || addressComponent.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  }
  else if (addressComponent && !addressRegex.test(addressComponent)) {
    return `${name?name:"Input"} has invalid characters`;
  }
  else if (addressComponent && addressComponent.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getErrorMsgForAddress = (address: string | undefined, minLength: number, name?: string, canBeEmpty?: boolean): string | undefined => {
  const addressRegex = /^[a-zA-Z0-9\s,.'-\/]*$/;
  if ((address === undefined || address.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  }
  else if (address && !addressRegex.test(address)) {
    return "Invalid characters";
  }
  else if (address && address.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getErrorMsgForContactNumber = (contactNumber: string, canBeEmpty?: boolean) => {
  if (!canBeEmpty && contactNumber.trim().length === 0) {
    return EMPTY_INPUT_HELPER_MSG;
  }

  // This regex pattern allows:
  // - Plus signs, digits, spaces, brackets, and hyphens anywhere in the string
  // - Ensures the string contains at least one digit
  const phoneRegex = /^[\s-()]*\+?[\s-()]*([0-9][\s-()0-9]*)+$/;

  if (contactNumber && !phoneRegex.test(contactNumber)) {
    return "Please enter a valid contact number";
  }
};

export const getErrorMsgForYear = (year: string, canBeEmpty?: boolean) => {
  if (!canBeEmpty && year.trim().length === 0) {
    return "Year cannot be empty";
  }
  const yearRegex = /^\d{4}$/;
  if (!yearRegex.test(year)) {
    return "Please enter a valid year";
  }
}
export const getErrorMsgForProfileUrl = (url: string, canBeEmpty?: boolean, name?: string) => {
  if (!canBeEmpty && url.trim().length === 0) { return "URL cannot be empty"; }
  const profileUrlRegex = /^(http|https):\/\//;
  if (url && !profileUrlRegex.test(url)) {
    return `Please enter a valid URL${name?' for '+name:''}`;
  }
};

export const getErrorMsgForUrl = (url: string, canBeEmpty?: boolean, name?: string) => {
  if (!canBeEmpty && url.trim().length === 0) {
    return "URL cannot be empty";
  }
  const urlRegex = new RegExp(
    '^((https?:\\/\\/)?' + // Optional protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?)$', // fragment locator
    'i'
  );
  if (url && !urlRegex.test(url)) {
    return `Please enter a valid URL${name ? ' for ' + name : ''}`;
  }
};

export const emailValidator = (email: string) => {
    return !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(email)
}

export const getHelpDeskMessageError = (message: string | undefined, minLength: number, name?: string, canBeEmpty: boolean = false, ) => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof message !== "string") {
    return "Message should be a valid string";
  } else if (message && !/^[a-zA-Z0-9\s.,!?_'\/-]*$/.test(message)) { // Adjust the regex as needed
    return "Invalid characters";
  } else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  }
};

export const getAlumniProfileError = (message: string | undefined, minLength: number, maxLength:number, name?: string, canBeEmpty: boolean = false) => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof message !== "string") {
    return `${name ? name : "Input"} should be a valid string`;
  } else if (message && !/^[a-zA-Z0-9\s.,!?_@#$%^&*:;<>{}[\](|)\\+=`"~'\/-]*$/.test(message)) { // Adjust the regex as needed
    return `${name ? name : "Input"} has invalid characters`;
  } else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  } else if (message && message.trim().length >= maxLength) {
    return `${name ? name : "Input"} should be less than ${maxLength} characters`;
  }
};



export const getContactUsError = (message: string | undefined, minLength: number, name?: string, canBeEmpty: boolean = false) => {
  // Check if the input is allowed to be empty
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG; // Assuming EMPTY_INPUT_HELPER_MSG is a constant for "Input cannot be empty"
  } 
  // Check if message is not a string
  else if (typeof message !== "string") {
    return "Message should be a valid string.";
  } 
  // Check for any invalid characters using a more lenient regex that allows spaces and common punctuations
  else if (message && !/^[a-zA-Z0-9\s.,!?_@#$%^&*:;<>{}[\]()|\\+=`"~'\/-]*$/.test(message)) {
    return "Invalid characters detected.";
  }
  // Check if message length is less than the minimum required length
  else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} must be at least ${minLength} characters long.`;
  }
};

export const getHelpCenterMessageError = (message: string | undefined, minLength: number, maxLength: number, name: string, canBeEmpty: boolean = false): string | undefined => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return "This field cannot be empty.";
  } else if (message && message.trim().length < minLength) {
    // Adjust the message to reflect the requirement correctly
    return `${name} must be more than ${minLength - 1} characters.`;
  } else if (message && message.trim().length >= maxLength) {
    // Adjust the message to use the `maxLength` parameter
    return `${name} must be less than ${maxLength} characters.`;
  }
};
// export const addNewEducationValidator = (input: string | undefined) => {
//   if (input === undefined || input.trim().length < 2) {
//     return "Minimum two characters required";
//   } else if (input.trim().length > 320) {
//     return "Input should be no more than 320 characters long";
//   } else if (!/^[.'-]*[a-zA-Z\s]*[.'-]*$/.test(input)) {
//     return "Input contains invalid characters or special characters at the beginning/end";
//   } else if (/\d/.test(input)) {
//     return "Input cannot contain numbers";
//   }
// };
export const addNewEducationValidator = (input: string | undefined, isMandatory: boolean = false, errorName?: string, maxLength?: number) => {
  if (isMandatory && (input === undefined || input.trim() === "")) {
    return `${errorName ? errorName : "Input"} cannot be empty`;
  } else if (input === undefined || input.trim().length < 2) {
    return "Minimum two characters required";
  } else if (input.trim().length > 320) {
    return "Input should be no more than 320 characters long";
  } else if (!/^[.',-]*[a-zA-Z\s][a-zA-Z\s.',-]*$/.test(input)) {
    return "Input contains invalid characters or special characters at the beginning/end";
  } else if (/\d/.test(input)) {
    return "Input cannot contain numbers";
  } else if (input && maxLength && input.trim().length >= maxLength) {
    return `${errorName ? errorName : "Input"} should be less than ${maxLength} characters`;
  }
  return undefined; // Return undefined if no error is found
};


export const getFormTitleError = (message: string | undefined, minLength: number, maxLength: number, name?: string, canBeEmpty: boolean = false) => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof message !== "string") {
    return "Message should be a valid string";
  } else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  } else if (message && message.trim().length > maxLength) {
    return `${name ? name : "Input"} should be less than ${maxLength} characters`;
  }
};

export const getFormHeaderError = (message: string | undefined, minLength: number, maxLength: number, name?: string, canBeEmpty: boolean = false) => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof message !== "string") {
    return "Message should be a valid string";
  } else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  } else if (message && message.trim().length > maxLength) {
    return `${name ? name : "Input"} should be less than ${maxLength} characters`;
  }
};

export const getFormDescriptionError = (message: string | undefined, minLength: number, maxLength: number, name?: string, canBeEmpty: boolean = false) => {
  if ((message === undefined || message.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof message !== "string") {
    return "Message should be a valid string";
  } else if (message && message.trim().length < minLength) {
    return `${name ? name : "Input"} should be more than ${minLength} characters`;
  } else if (message && message.trim().length > maxLength) {
    return `${name ? name : "Input"} should be less than ${maxLength} characters`;
  }
};
export const getErrorMsgForTitle = (title: string | undefined, minLength: number, name?: string, canBeEmpty?: boolean): string | undefined => {
  // Allows any characters but checks if the first character is a special character through a separate condition
  if ((title === undefined || title.trim().length === 0) && !canBeEmpty) {
    return "This field cannot be empty."; // Or use a constant like EMPTY_INPUT_HELPER_MSG
  }
  else if (title && /[^a-zA-Z0-9]/.test(title.trim().charAt(0))) {
    return "The title cannot start with a special character."; // Error if the first character is not alphanumeric
  }
  else if (title && title.trim().length < minLength) {
    return `${name ? name : "Title"} must be at least ${minLength} characters.`; // Customize based on input name
  }
};

export const getErrorMsgForSubject = (subject: string | undefined, minLength: number, maxLength: number, name: string, canBeEmpty?: boolean): string | undefined => {
  // Check if the subject can be empty
  if ((subject === undefined || subject.trim().length === 0) && !canBeEmpty) {
    return "This field cannot be empty."; // Or use a constant like EMPTY_INPUT_HELPER_MSG
  } else if (subject && subject.length < minLength) {
    // Check if the subject is less than the minimum length
    return `${name} must be more than 2 characters.`;
  } else if (subject && subject.length >= maxLength) {
    // Check if the subject exceeds the maximum length
    return `${name} must be less than 100 characters.`;
  }
};

export const validateLocationOfEngagement = (input: string | undefined, name: string = "Location of Engagement", canBeEmpty: boolean = false) => {
  const minLength = 2;
  const maxLength = 64;
  // Updated regex to allow parentheses in a more practical context
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9 .,'!&()-]*[a-zA-Z0-9).'!&-]$/;


  if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return "Input cannot be empty";
  } else if (typeof input !== "string") {
    return `${name} should be a valid string`;
  } else if (input && !regex.test(input.trim())) {
    return `Input contains invalid characters`;
  } else if (input.trim().length < minLength) {
    return `${name} should be at least ${minLength} characters`;
  } else if (input.trim().length > maxLength) {
    return `${name} should be less than ${maxLength} characters`;
  }
  return;
};

export const validateEngagementTitle = (input: string | undefined, name: string = "Engagement Title", canBeEmpty: boolean = false) => {
  const minLength = 2;
  const maxLength = 64;
  // Regex allows specific special characters but not as the first or last character and not consecutively
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9 .,'!&()-]*[a-zA-Z0-9).'!&-]$/;

  if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof input !== "string") {
    return `${name} should be a valid string`;
  } else if (input && !regex.test(input.trim())) {
    return `Input contains invalid characters`;
  } else if (input.trim().length < minLength) {
    return `${name} should be at least ${minLength} characters`;
  } else if (input.trim().length > maxLength) {
    return `${name} should be less than ${maxLength} characters`;
  }
  return;
};

export const validateTypeOfEngagement = (input: string | undefined, name: string = "Type of Engagement") => {
  const minLength = 2;
  const maxLength = 64;
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9 .,'!&()-]*[a-zA-Z0-9).'!&-]$/;

  if (input === undefined || input.trim().length === 0) {
    return; // It's optional, return nothing if empty
  } else if (typeof input !== "string") {
    return `${name} should be a valid string`;
  } else if (input && !regex.test(input.trim())) {
    return `Input contains invalid characters`;
  } else if (input.trim().length < minLength) {
    return `${name} should be at least ${minLength} characters`;
  } else if (input.trim().length > maxLength) {
    return `${name} should be less than ${maxLength} characters`;
  }
  return;
};


export const validateAgenda = (input: string | undefined, name: string, canBeEmpty: boolean = false) => {
  const minLength = 2;
  const maxLength = 300;

  if ((input === undefined || input.trim().length === 0) && !canBeEmpty) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (typeof input !== "string") {
    return `${name} should be a valid string`;
  } else if ( input && input.trim().length < minLength) {
    return `${name} should be at least ${minLength} characters`;
  } else if ( input && input.trim().length > maxLength) {
    return `${name} should be less than ${maxLength} characters`;
  }
  return;
};







