import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, styled } from "@mui/material";
import styles from './index.module.scss';
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactNode } from "react";
import { pxToRem } from "../../helpers/setRem";

export const CustomDialog = styled(Dialog)({
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "100%",
        // maxWidth can be uncommented and adjusted for mobile
        '@media (max-width: 780px)': { // This applies to screens smaller than 600px
          maxWidth: "100%", // You might want to limit to a percentage or specific value
        },
      },
    },
  
    '& .MuiDialogTitle-root': {
      padding: `${pxToRem(40)} ${pxToRem(100)}`,
      paddingBottom: 0,
      '@media (max-width: 780px)': { // This applies to screens smaller than 600px
        padding: `${pxToRem(40)} ${pxToRem(24)} ${pxToRem(20)} ${pxToRem(24)}  `, // Reduce padding for smaller screens
      },
    },
    '& .MuiDialogContent-root': {
      padding: pxToRem(40),
      paddingBottom: '0 !important',
      '@media (max-width: 780px)': { // This applies to screens smaller than 600px
        padding: `${pxToRem(20)} ${pxToRem(24)}`, // Adjust padding for mobile
      },
    },
    '& .MuiDialogActions-root': {
      padding: `${pxToRem(24)} ${pxToRem(40)} !important`,
      paddingTop: 0,
      '@media (max-width: 780px)': { // This applies to screens smaller than 600px
        padding: `${pxToRem(16)} ${pxToRem(20)} !important`, // Less padding on the actions for smaller screens
      },
    }
  });

export const CustomButton = styled(LoadingButton)({
    height: pxToRem(36),
    minWidth: pxToRem(164),
    padding: `${pxToRem(8)}px ${pxToRem(16)}px`,
    borderRadius: pxToRem(40),
    background: styles.primaryBlue500,

    color: styles.shades0,
    textAlign: 'center',
    fontSize: pxToRem(14),
    fontWeight: '400',
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(-0.28),

    "&:hover": {
        backgroundColor: styles.secondaryBlue300,
    }
})

export const CustomOutlined = styled(LoadingButton)({
    height: pxToRem(36),
    minWidth: pxToRem(164),
    padding: `${pxToRem(8)}px ${pxToRem(16)}px`,
    borderRadius: pxToRem(40),
    border: `1px solid ${styles.primaryBlue500}`,
    color: styles.primaryBlue500,

    textAlign: 'center',
    fontSize: pxToRem(14),
    fontWeight: '400',
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(-0.28),
    '&:hover': {
        color: styles.secondaryBlue300,
        borderColor: styles.secondaryBlue300,
        background:'inherit'
    },
    '&:disabled': {
        color: styles.neutrals500
    }
})

export const DangerButton = styled(LoadingButton)({
    height: pxToRem(36),
    minWidth: pxToRem(164),
    padding: `${pxToRem(8)}px ${pxToRem(16)}px`,
    borderRadius: pxToRem(40),
    background: styles.destructiveRed500,

    color: styles.shades0,
    textAlign: 'center',
    fontSize: pxToRem(14),
    fontWeight: '400',
    lineHeight: pxToRem(20),
    letterSpacing: pxToRem(-0.28),

    "&:hover": {
        backgroundColor: '#f56f67'
    },
    "&.Mui-disabled": {
        color: styles.neutrals500,
        backgroundColor: styles.neutrals200
    }
})


interface IUserAlert {
    open:boolean;
    handleClose: ()=>void;
    title?: string;
    content?: string | ReactNode;
    handleAction?: () => void;
    actionButtonText?: string;
    closeButtonText?: string;
    closeOutlined?: boolean;
}

const UserAlert = (props: IUserAlert) => {

    const newlineText = (text: string) => {
        return text.split('\n').map((str, i) => <p key={`${props.title}_alerttext_${i}`}>{str}<br/></p>)
    }

    return (
        <CustomDialog open={props.open}>
            {props.title && <DialogTitle><Stack alignItems={'center'}><h5 style={{textAlign:'center', color: '#555555', marginBottom: pxToRem(6)}}>{props.title}</h5></Stack></DialogTitle>}
            <DialogContent>
                {
                    typeof props.content === 'string' ?
                        props.content && <Stack alignItems={'center'}><p style={{margin: 0, textAlign:'center'}}>{newlineText(props.content)}</p></Stack>
                    :
                        <Stack alignItems={'center'}>{props.content}</Stack>
                }
                <Divider sx={{width:'100%',  marginTop:`${pxToRem(40)}`}}/>
            </DialogContent>
            <DialogActions sx={{
                justifyContent: props.handleAction?'space-between':'center'
            }}>
                <button className={styles.sendButton} onClick={props.handleClose}>{props.closeButtonText?props.closeButtonText:'Close'}</button>
            
            </DialogActions>
        </CustomDialog>
    )
};

export default UserAlert; 