import { Fade, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "swiper/css/pagination";
import unimelbDoor from "../../../assets/images/LandingPage/Internship/unimelbDoorImg.png";
import InternshipTitle from "../../../assets/images/LandingPage/Internship/InternshipTitle.png";
import unimelbBigLogo from "../../../assets/images/LandingPage/Internship/unimelbLogoBig.png";
import OfficeLogo from "../../../assets/images/LandingPage/Internship/officeImg2.png";

import AnimationController from "../../../components/AnimationController/AnimationController";
import styles from "./index.module.scss";
import { pxToRem } from "../../../helpers/setRem";



const Internship = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:780px');
    const [activeSlide, setActiveSlide] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);
    const testimonials = [
        {
            text: "My team was exceptionally supportive and always eager to help. I had the unique opportunity to analyse and develop solutions that genuinely helped others! My day-to-day learning contributed immensely to my professional development.",
            author: "- Jan, Software Developer Intern",
            bgColor: "#96231D" // Light gray background for the first testimonial
        },
        {
            text: "My internship at Kepple gave me hands-on experience from day one, with significant responsibilities and visible impact. The supportive environment allowed me to push boundaries and cultivate both personal and professional growth.",
            author: "- Keigo, Software Developer Intern",
            bgColor: "#B02822" // Slightly darker gray for the second testimonial
        }
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 100); // Delay might be adjusted based on your needs

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [pathname]);



    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((current) => (current === testimonials.length - 1 ? 0 : current + 1));
        }, 8000); // Change to next testimonial every 5 seconds

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, [testimonials.length]);


    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0,  maxWidth: '1900px', width: '100%', alignSelf: 'center'}}>
            <Stack direction="column" marginTop={isMobile ? pxToRem(86) : pxToRem(80)}> {/* Adjusted pxToRem to simple string for simplification */}
                
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_2}>
                                <Stack className={styles.home_2_textContainer}>
                                <Typography className={styles.home_title}>
                                    Internship Opportunities
                                </Typography>
                                    <Typography className={styles.home_2_text}>
                                        Kepple offers internship opportunities to university students approaching the end of their degrees.<br /><br />
                                        Our internship program is designed to give students the chance to develop their skills through real-life projects, allowing them to apply their knowledge and creativity in a practical setting.
                                    </Typography>
                                    <button
                                        className={styles.unimelbButton}
                                        onClick={() => navigate('/apply')}
                                    >
                                        <Typography className={styles.unimelbButtonText}>
                                            Apply Now
                                        </Typography>
                                    </button>
                                </Stack>
                                <Stack className={styles.home_2_boxContainer}>
                                    <img className={styles.home_2_Img} src={InternshipTitle} alt="Internship opportunities at Kepple" />
                                </Stack>

                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_3}>
                                <Typography className={styles.home_3_title}>
                                Kepple has hosted interns from the University of Melbourne's Faculty of Engineering and IT internship program since 2022.       </Typography>
                                <img className={styles.home_3_img} src={unimelbBigLogo} alt="Unimelb Logo" />
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_4}>

                                <Stack className={styles.home_4_textContainer}>
                                    <Typography className={styles.home_4_title}>
                                        Day in the life of an intern at Kepple
                                    </Typography>
                                    <Typography className={styles.home_4_text}>
                                        Our interns work on bite-sized projects that they can see from start to finish by the end of their internship. <br /><br />
                                        This approach ensures that each day brings new challenges and you'll see tangible progress towards a finished product. Our team is here to support you with regular check-ins and immediate assistance whenever needed.<br />
                                    </Typography>
                                </Stack>
                                <Stack className={styles.home_4_imgContainer}>
                                    <img className={styles.home_4_img} src={OfficeLogo} alt="Internship experience at the Kepple office" />
                                </Stack>
                                <Grid container className={styles.home_4_gridBox} >
                                    <Grid className={styles.home_4_gridColumn}>
                                        <Stack gap={pxToRem(24)}>
                                            <Typography className={styles.home_4_gridTitle}>
                                                Work on something meaningful
                                            </Typography>
                                            <Typography className={styles.home_4_gridtext}>
                                                All projects are real and have purpose. They are moving our business forward and we are invested in helping you succeed.
                                            </Typography>

                                        </Stack>
                                    </Grid>
                                    <Grid className={styles.home_4_gridColumn}>
                                        <Stack gap={pxToRem(24)}>
                                            <Typography className={styles.home_4_gridTitle}>
                                                Experience life at a start-up
                                            </Typography>
                                            <Typography className={styles.home_4_gridtext}>
                                                If you think a startup environment suits you, gaining real-life experience is the best way to find out.                                            </Typography>

                                        </Stack>
                                    </Grid>

                                    <Grid className={styles.home_4_gridColumn}>
                                        <Stack gap={pxToRem(24)}>
                                            <Typography className={styles.home_4_gridTitle}>
                                                Feel well supported, every day
                                            </Typography>
                                            <Typography className={styles.home_4_gridtext}>
                                                Each intern is assigned a direct manager with expertise in their field and are available to assist whenever needed.                                            </Typography>

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.1}
                    animationCanReplay={false}
                />

                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation}  timeout={1000}>
                            <Stack className={styles.home_5} style={{ backgroundColor: testimonials[activeIndex].bgColor }}>
                                <Fade in={true} timeout={{ enter: 2000 }} key={activeIndex} style={{ transitionDelay: '500ms' }}>
                                    <Stack gap={pxToRem(24)}>
                                        <Typography className={styles.home_5_title}>
                                            {testimonials[activeIndex].text}
                                        </Typography>
                                        <Fade in={true} timeout={{ enter: 2500 }} style={{ transitionDelay: '1000ms' }}>
                                            <Typography className={styles.home_5_text}>
                                                {testimonials[activeIndex].author}
                                            </Typography>
                                        </Fade>
                                    </Stack>
                                </Fade>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
                <AnimationController
                    render={(loadedAnimation) => (
                        <Fade in={loadedAnimation} timeout={1000}>
                            <Stack className={styles.home_6} direction={'column'}>
                                <Stack className={styles.home_6_Container}>
                                    <Stack className={styles.home_6_textBox} direction={'column'}>
                                        <Typography className={styles.home_6_titleBold}>
                                            Got questions?
                                        </Typography>

                                        <Typography className={styles.home_6_text}>
                                            We'd love to answer them. Send us a message!                                        </Typography>
                                    </Stack>
                                    <button className={styles.contactButton} onClick={() => navigate('/contact')}>
                                        <Typography className={styles.contactButtonText}>
                                            Contact Us
                                        </Typography>
                                    </button>
                                </Stack>
                            </Stack>
                        </Fade>
                    )}
                    customThreshold={0.5}
                    animationCanReplay={false}
                />
            </Stack>
        </div>
    );
};

export default Internship;
