import { DialogContent, Fade, Grid, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { getContactUsError, getErrorMsgForContactNumber, getErrorMsgForEmailInput, getErrorMsgForInput, getErrorMsgForSubject, validateAgenda } from "../../../helpers/inputValidators";
import useTextInput from "../../../hooks/useTextInput";
import styles from "./Contact.module.scss";
import { useLocation } from "react-router-dom";
import { pxToRem } from "../../../helpers/setRem";
import { WhiteMultilineTextField, WhiteTextField } from "../../../components/TextField";
import UserAlert from "../../../components/AlertComponent/BasicAlert";
import { LoadingButton } from "@mui/lab";
import { formatFileSize } from "../Contact";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { uploadAllTypeFileToStorage } from "../../../firebase/storageApis";


const ApplyPage = () => {

    const isMobile = useMediaQuery('(max-width:440px');
    const name = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "First Name", true) } });
    const title = useTextInput({ inputValidator: (input) => { return getErrorMsgForInput(input, 2, "Job Title", true) } });
    const whyKepple = useTextInput({ inputValidator: (input) => { return validateAgenda(input, 'This Field', true) } });
    const number = useTextInput({ inputValidator: (input) => { return getErrorMsgForContactNumber(input, true); } });
    const email = useTextInput({ inputValidator: (input) => { return getErrorMsgForEmailInput(input, true) } });
    const message = useTextInput({ inputValidator: (input) => { return getContactUsError(input, 2, "About You", true); } });
    const position = useTextInput({ inputValidator: (input) => { return getContactUsError(input, 2, "Project", true); } });
    const [loading, setLoading] = useState(false)


    const [attachText, setAttachText] = useState("Attach CV")
    const [file, setFile] = useState<File | null>(null); // Assuming you have a state to keep the file
    const [fileName, setFileName] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const isUnderline = attachText !== "Attach CV";

    // Combine classes conditionally
    const paragraphClassName = `${styles.paragraphClass} ${isUnderline ? styles.paragraphClassUnderline : ''}`;


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const allowedTypes = [
                'application/pdf',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'image/jpeg',
                'image/png',
                'text/csv'
            ];

            if (!allowedTypes.includes(file.type) || file.size > 10485760) { // 10MB
                setAlertOpen(true);
                return;
            }

            setFile(file);
            setFileName(file.name);

            // Format file size for display
            const fileSize = formatFileSize(file.size);

            // Set attach text with filename and size
            setAttachText(`${file.name.length > 25 ? `${file.name.substring(0, 25)}...` : file.name} (${fileSize})`);
        }
    }



    const emailServerUrl = "https://public.herotofu.com/v1/ab7c67e0-92e0-11ed-a003-6f0b76086b1c";
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const textFieldProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const multilineProps = {
        sx: {
            borderRadius: '10px',
            '& .MuiOutlinedInput-root':
            {
                padding: 0,
                'fieldset': { border: `1px solid ${styles.primary400}`, },
                '&.Mui-focused fieldset': { border: `2px solid ${styles.primary500}` },
                '&:hover fieldset': { border: `2px solid ${styles.primary400}`, }
            }
        },
        inputProps: {
            style: {
                color: styles.shades100
            },
            maxLength: 500
        },
        InputLabelProps: {
            style: {
                color: styles.primary400
            }
        }
    }

    const resetForm = () => {
        // Resetting the value to an empty string and marking it as untouched
        name.setValue("");
        name.setHasTouched(false);

        email.setValue("");
        email.setHasTouched(false);

        number.setValue("");
        number.setHasTouched(false);

        message.setValue("");
        message.setHasTouched(false);

        title.setValue("");
        title.setHasTouched(false);

        position.setValue("");
        position.setHasTouched(false);

        whyKepple.setValue("");
        whyKepple.setHasTouched(false);
        setFile(null);
        setFileName("");
        setAttachText("Attach CV");
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true); // Assume setLoading is defined somewhere to handle loading state

        let fileUrl = '';
        if (file) { // Assuming `file` is part of your component's state
            const filePath = `0000000000000-PUBLIC/keppleweb/applyJob/${file.name}`;
            try {
                const uploadResult = await uploadAllTypeFileToStorage({ file, path: filePath });
                if (uploadResult.code !== 200) {
                    throw new Error("Failed to upload file.");
                }
                fileUrl = uploadResult.data; // URL of the uploaded file
            } catch (error) {
                console.error("Error uploading file:", error);
                setAlertOpen(true);
                setLoading(false);
                return;
            }
        }

        // Combine all form data into one formatted message
        const formattedMessage = `
        CONTACT NUMBER: ${number.value}\n
        JOB TITLE: ${title.value}\n
        PERSONAL ADVANTAGE: ${message.value}\n
        POSITION APPLY: ${position.value}\n
        WHY KEPPLE: ${whyKepple.value}\n
        FILE URL: ${fileUrl}
    `;

        // Sending the data to the server
        fetch(emailServerUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.value, // The recipient's email
                name: name.value,
                message: formattedMessage, // Pass the entire formatted message
            }),
        }).then(response => {
            if (response.status === 200) {
                console.log("Email sent successfully");
                setOpenAlert(true);
                resetForm(); // Reset the form on successful submission
            } else {
                throw new Error("Failed to send email.");
            }
        }).catch(error => {
            console.error("Failed to send request:", error);
            alert('Failed to send request. Please try again.')
        }).finally(() => {
            setLoading(false);
        });
    }

    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    useEffect(() => {
        ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, [pathname])

    return (
        <div ref={ref} style={{ backgroundColor: styles.shades0, width: '100%', alignSelf: 'center' }}>
            <Stack direction="column" width={'100%'} marginTop={isMobile ? pxToRem(80) :pxToRem(80)}>
                <UserAlert
                    open={openAlert}
                    handleClose={() => setOpenAlert(false)}
                    title="Thank you for reaching out!" // No title in your example
                    content="We have received your query and will endeavour to respond to you within 3 business days. We appreciate your patience as our team processes your request."
                    closeButtonText="Close"
                    closeOutlined={true}
                />
                <UserAlert
                    open={alertOpen}
                    handleClose={() => setAlertOpen(false)}
                    title="Uploading File" // No title in your example
                    content="Unfortunately, the file you are attempting to upload exceeds our size limit of 10MB. Please select a smaller file and try again. We appreciate your understanding. "
                    closeButtonText="Close"
                    closeOutlined={true}
                />
                <Stack className={styles.contact1}>
                    <h2>Interested in joining our team?</h2>
                    <p>We'd love to hear from you.</p>
                </Stack>

                <Stack className={styles.contact2}>
                    <h2>Apply Here</h2>
                    <p>All fields required</p>
                    <Fade in={true} timeout={1000}>
                        <Stack className={styles.formContainer}>

                            <form
                                action={emailServerUrl}
                                onSubmit={handleSubmit}>
                                <Grid container gap={3} className={styles.gridContainer}>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            variant="filled"
                                            value={name.value}
                                            onChange={(e) => name.setValue(e.target.value)}
                                            label='Full Name'
                                            name="fullName"
                                            error={name.hasError && name.hasTouched}
                                            helperText={<small>{name.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={email.value}
                                            variant="filled"
                                            onChange={(e) => email.setValue(e.target.value)}
                                            label='Email Address'
                                            name="email"
                                            type="email"
                                            error={email.hasError && email.hasTouched}
                                            helperText={<small>{email.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={number.value}
                                            variant="filled"
                                            onChange={(e) => number.setValue(e.target.value)}
                                            label='Contact Number'
                                            name="number"
                                            error={number.hasError && number.hasTouched}
                                            helperText={<small>{number.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={title.value}
                                            variant="filled"
                                            onChange={(e) => title.setValue(e.target.value)}
                                            label='Your Current Job Title'
                                            name="Job title"
                                            error={title.hasError && title.hasTouched}
                                            helperText={<small>{title.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Stack width={'100%'} marginTop={pxToRem(40)}>
                                        <Typography className={styles.formTitle}>Which position are you applying for?</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={position.value}
                                            variant="filled"
                                            onChange={(e) => position.setValue(e.target.value)}
                                            label='Position Title'
                                            name="position"
                                            error={position.hasError && position.hasTouched}
                                            helperText={<small>{position.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}
                                        />
                                    </Grid>

                                    <Stack width={'100%'} marginTop={pxToRem(40)}>
                                        <Typography className={styles.formTitle}>What makes you a good fit?</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteMultilineTextField
                                            fullWidth
                                            value={message.value}
                                            onChange={(e) => message.setValue(e.target.value)}
                                            placeholder="Tell us about what makes you a good fit for this role."
                                            name="message"
                                            multiline
                                            error={message.hasError && message.hasTouched}
                                            helperText={<small>{message.getErrorMessage()}</small>}
                                            rows={2}
                                            required
                                            {...multilineProps}
                                        />
                                    </Grid>

                                    <Stack width={'100%'} marginTop={pxToRem(40)}>
                                        <Typography className={styles.formTitle}>Why Kepple?</Typography>
                                    </Stack>

                                    <Grid item xs={12}>
                                        <WhiteTextField
                                            fullWidth
                                            value={whyKepple.value}
                                            variant="filled"
                                            onChange={(e) => whyKepple.setValue(e.target.value)}
                                            placeholder="We would love to know!"
                                            name="number"
                                            error={whyKepple.hasError && whyKepple.hasTouched}
                                            helperText={<small>{whyKepple.getErrorMessage()}</small>}
                                            required
                                            {...textFieldProps}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction={'row'} style={{ alignItems: 'start'}}>
                                            <input
                                                accept=".pdf,.csv,.xls,.xlsx,.doc,.docx,.jpg,.png"
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                            <label htmlFor="raised-button-file" style={{ cursor: 'pointer' }}>
                                                <Stack direction={"row"}>
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.1525 9.89952L10.1369 18.9151C8.08662 20.9654 4.7625 20.9654 2.71225 18.9151C0.661997 16.8649 0.661998 13.5408 2.71225 11.4905L11.7279 2.47489C13.0947 1.10806 15.3108 1.10806 16.6776 2.47489C18.0444 3.84173 18.0444 6.05781 16.6776 7.42464L8.01555 16.0867C7.33213 16.7701 6.22409 16.7701 5.54068 16.0867C4.85726 15.4033 4.85726 14.2952 5.54068 13.6118L13.1421 6.01043" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <p className={paragraphClassName}>
                                                        {attachText}
                                                    </p>

                                                </Stack>
                                            </label>
                                            {file && (
                                                <Stack onClick={() => { setFile(null); setFileName(''); setAttachText('Attach CV') }}>
                                                    <DeleteOutlinedIcon sx={{ color: 'white', cursor: 'pointer' }} />
                                                </Stack>

                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Stack alignItems={'center'} marginTop={pxToRem(12)}>
                                            <LoadingButton
                                                loading={loading}
                                                type="submit"
                                                disabled={
                                                    // Disable if any field has an error
                                                    name.hasError ||
                                                    email.hasError ||
                                                    number.hasError ||
                                                    message.hasError ||
                                                    position.hasError ||
                                                    title.hasError ||
                                                    whyKepple.hasError ||
                                                    // Or if any field has not been touched
                                                    name.value === '' ||
                                                    email.value === '' ||
                                                    number.value === '' ||
                                                    message.value === '' ||
                                                    position.value === '' ||
                                                    title.value === '' ||
                                                    whyKepple.value === ''
                                                }
                                                className={styles.sendButton}
                                            >
                                                {loading ? '' : 'SUBMIT'}
                                            </LoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Fade>
                </Stack>
            </Stack>
        </div>
    )
}


export default ApplyPage;
