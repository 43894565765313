import { useEffect, useMemo, useRef, useState } from "react";

interface IuseTextInput {
  inputValidator: (value: string) => string | undefined;
  defaultValue?: string;
}

const useTextInput = ({ inputValidator, defaultValue }: IuseTextInput) => {
  const [value, setValue] = useState(defaultValue ?? "");
  const [hasTouched, setHasTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const firstTimeRender = useRef(true);

  const hasError = useMemo(() => {
    if (errorMessage) {
      return true;
    }
    return false;
  }, [errorMessage != undefined]);

  const getErrorMessage = () => (hasTouched ? errorMessage : "");

  useEffect(() => {
    if (firstTimeRender.current) {
      firstTimeRender.current = false;
    } else {
      if (!hasTouched) {
        setHasTouched(true);
      }
    }
    setErrorMessage(inputValidator(value));
  }, [value]);

  return {
    value,
    setValue,
    hasTouched,
    getErrorMessage,
    hasError,
    setHasTouched,
  };
};

export type UseTextInput = ReturnType<typeof useTextInput>;

export default useTextInput;
