import React from 'react';
import styles from "./Slide.module.scss";
import SlideContentContainer from "./SlideContentContainer";
import { IContentWithAnimation } from '../../../../global/types';

interface ISlide extends IContentWithAnimation {
  backgroundImageUrl?: string;
  title: string;
  description: string;
  buttonString?: string;
  productImg?: JSX.Element;
}

const Slide = ({
  loadedAnimation,
  title,
  description,
  backgroundImageUrl,
}: ISlide) => (
  <div className={styles.page}>
    {backgroundImageUrl ? (
      <img
        className={styles.backgroundImage}
        src={backgroundImageUrl}
        alt='Company Description Background'
      />
    ) : (
      <div className={styles.defaultSlideBackground} /> // Add default background style if needed
    )}
    <div className={styles.contentContainer}>
      <SlideContentContainer
        title={title}
        description={description}
        loadedAnimation={loadedAnimation}
      />
    </div>
  </div>
);

export default Slide;